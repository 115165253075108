import React from 'react';
import { File } from '@model/generated/index';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '@components/global/Layout';
import { RouteEnum } from '@model/route.enum';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  image5: File;
  image6: File;
  image7: File;
  image8: File;
  image9: File;
  image10: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "info/image1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "info/image2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "info/image3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "info/image4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "info/image5.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "info/image6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "info/image7.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image8: file(relativePath: { eq: "info/image8.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image9: file(relativePath: { eq: "info/image9.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image10: file(relativePath: { eq: "info/image10.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout
      title="Wunderkammer"
      navigationItems={[
        { title: 'Projekt', to: RouteEnum.INFO },
        { title: 'Termine', to: RouteEnum.EVENT },
        { title: 'Förderung', to: RouteEnum.SPONSORD },
      ]}
    >
      <div className="flex">
        <div className="flex-auto">
          <GatsbyImage
            className="w-full"
            objectFit="cover"
            image={data.image2.childImageSharp.gatsbyImageData}
            alt="Wunderkammer der Zukunft"
          />
          <div className="flex flex-col">
            <GatsbyImage
              className="-mt-92 self-end mr-32 w-1/2"
              objectFit="contain"
              image={data.image1.childImageSharp.gatsbyImageData}
              alt="Wunderkammer der Zukunft"
            />
            <div className="flex-1 p-32">
              <h3>Wunderkammer der Zukunft</h3>
              <p>
                Das Projekt <b>Wunderkammer der Zukunft</b> war immer als
                fortlaufender Prozess angelegt, nichts war von Beginn an „in
                Stein gemeißelt“. Einiges wurde verworfen, Neues ist
                hinzugekommen, nicht zuletzt auch durch die Corona-Pandemie, die
                seit März 2020 alle Kultureinrichtungen und damit auch die
                Museen vor ganz neue Herausforderungen stellte.
              </p>
              <p>
                Der Auftakt des <b>Wunderkammer</b>-Projektes war im September
                2018: Der INOVA-Turbo, ein glitzerndes Gefährt, in seinem Design
                angelehnt an den DeLorean aus dem Film „Zurück in die Zukunft“
                wurde im zentralen Lüdenscheider Einkaufszentrum SternCenter
                präsentiert und nahm die BesucherInnen mit auf eine Zeitreise.
                Im Laufe der folgenden zwei Jahre erblickte man den INOVA-Turbo
                an unterschiedlichen Orten im Stadtzentrum. Er diente als Symbol
                und Vehikel für die relevanten Zukunftsthemen der
                (Stadt)Gesellschaft und parkt zurzeit an exponierter Stelle in
                den Museen der Stadt Lüdenscheid.
              </p>
              <div className="flex flex-col">
                <GatsbyImage
                  className="my-8 md:my-0max-w-672 mt-32 w-full"
                  objectFit="cover"
                  image={data.image4.childImageSharp.gatsbyImageData}
                  alt="INOVA-Turbo"
                />
                <GatsbyImage
                  className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-3/4"
                  objectFit="cover"
                  image={data.image3.childImageSharp.gatsbyImageData}
                  alt="INOVA-Turbo"
                />
              </div>
              <p>
                Am 8. Mai 2019 konnte dann die <b>Wunderkammer der Zukunft</b>{' '}
                in den Museen der Stadt eröffnet werden. Aus dem ehemaligen
                „Münzkabinett“ der veralteten Dauerausstellung wurde ein
                Experimentier- und Repräsentationsort, gestaltet von dem
                polnischen Lichtkünstler Robert Sochacki. Es entstanden „60
                Quadratmeter Inspiration“, verwandelbar wahlweise in einen
                Working Space, ein alchimistisches Labor, eine
                Ausstellungsfläche, einen Kinosaal, einen Klassenraum…
              </p>
              <p>
                Die Eröffnung der <b>Wunderkammer</b> war zugleich der Auftakt
                zu einer Veranstaltungsreihe, die das Herzstück des Projektes
                bildete. In den kommenden Monaten diskutierten
                RatsvertreterInnen über „Demokratie und Populismus“ und
                VertreterInnen aus Pflegeinrichtungen und Krankenhaus,
                Betroffene und Angehörige machten sich Gedanken über
                „Demographie – Hochbetagte in der Lüdenscheider
                Stadtgesellschaft.
              </p>
              <div className="flex flex-col py-32">
                <GatsbyImage
                  className="my-8 md:my-0 md:-mb-40 lg:-mb-92-mb-92 self-end z-10 w-full md:w-1/3"
                  objectFit="contain"
                  image={data.image6.childImageSharp.gatsbyImageData}
                  alt="Raum Wunderkammer"
                />
                <GatsbyImage
                  className="my-8 md:my-0 max-w-672 w-full"
                  objectFit="cover"
                  image={data.image5.childImageSharp.gatsbyImageData}
                  alt="Raum Wunderkammer"
                />
              </div>
              <p>
                Umweltamt, Industrie, Politik und Energieversorger setzten sich
                mit den jungen VertreterInnen der Lüdenscheider „Fridays for
                Future“-Gruppe zum Thema „Klima und Umwelt“ auseinander.
                LehrerInnen, LehramtsanwärterInnen und VertreterInnen des
                Schulamtes dachten über „Bildung“ nach. Gemeinsam mit
                VertreternInnen aus Wirtschaft, Handel, Industrie und kreativer
                Szene wurden „Visionen für Lüdenscheid“ entwickelt. Auf der
                Agenda standen Themen wie „Kultivierte Angst“, „Generation Z“,
                „Kultur“ und nicht zuletzt die „Wunderkammer Lüdenscheid“. Der
                neu geschaffene Raum wurde für künstlerische Workshops und als
                außerschulischer Lernort genutzt – und war und ist zudem eine
                begehbare Kunstinstallation.
              </p>
              <p>
                Aus der Zusammenarbeit mit den <b>Stadtgefährten</b> und den
                Beiträgen der LüdenscheiderInnen entwickelten sich Inhalte und
                Formate, die nicht vorhersehbar waren. Durch die Zusammenarbeit
                mit Wissenschaft und Wirtschaft, Kreativen und Handwerk und der
                ganz unmittelbaren Beteiligung der BürgerInnen wurde in den
                Museen der Stadt Lüdenscheid ein umfassender partizipativer
                Prozess angestoßen.
              </p>
              <div className="flex flex-col">
                <GatsbyImage
                  className="my-8 md:my-0 md:-mb-40 lg:-mb-92 mt-32 self-end z-10 w-full md:w-1/2"
                  objectFit="cover"
                  image={data.image8.childImageSharp.gatsbyImageData}
                  alt="Veranstaltung Wunderkammer"
                />
                <GatsbyImage
                  className="my-8 md:my-0 max-w-672 w-full"
                  objectFit="contain"
                  image={data.image9.childImageSharp.gatsbyImageData}
                  alt="Veranstaltung Wunderkammer"
                />
                <GatsbyImage
                  className="my-8 md:my-0 md:-mt-40 lg:-mt-92 mb-32 self-end w-full md:w-1/3"
                  objectFit="cover"
                  image={data.image7.childImageSharp.gatsbyImageData}
                  alt="Veranstaltung Wunderkammer"
                />
              </div>
              <p>
                „Jeder sollte so eine Kammer haben“, befand der Futurologe Max
                Thinius, der zweimal – digital und „analog“ – als Referent zu
                Gast in der „Wunderkammer“ war. „Ich finde, dies ist ein
                einzigartiges Projekt, indem alle Parameter, die für die
                Zukunftsgestaltung einer Region wichtig sind, zusammenkommen.
                Gesellschaft; Wirtschaft, Politik, Kultur, Soziales, Bildung,
                Ethik…und Technologie. Und das in Form von Lösungen, Szenarien
                und praktisch-ethischer Auseinandersetzung.“
              </p>
              <GatsbyImage
                className="max-w-672 my-32 w-full"
                objectFit="cover"
                image={data.image10.childImageSharp.gatsbyImageData}
                alt="Max Thinius"
              />
              <p>
                Seit Beginn der Corona-Pandemie ist der Kontakt und die
                Interaktion mit dem Museumspublikum, bedingt durch Sicherheits-
                und Abstandsregeln, nur sehr eingeschränkt möglich. Zeitweise
                waren oder sind die Türen aller Kultureinrichtungen geschlossen.
              </p>
              <p>
                Und so soll das Konzept der „Wunderkammer“, das sich durch den
                Austausch und die physische Präsenz der Akteure auszeichnet, auf
                den virtuellen Raum erweitert werden. Dies soll geschehen mit
                den Mitteln der Kunst. Die „Wunderkammer der Zukunft“ wird in
                den Stadtraum verlagert und dient dem Transport von Thesen,
                Ideen, Gedanken, Konzepten und Ansätzen, die sich mit der
                Zukunft der Stadt beschäftigen. Entwickelt werden diese
                Interventionen von Künstlern, die unter anderem bereits während
                des Lichtkunstfestivals „LichtRouten“ den Stadtraum bespielt
                haben. Jeweils ein Künstler wird beauftragt, sich mit einem der
                gesellschaftsrelevanten Themen der „Wunderkammer“
                auseinanderzusetzen. Diese Themen werden aus künstlerischer
                Sicht interpretiert. Von außen weithin sichtbar wird auch das
                Format der „Wunderkammer“ – Diskussionen, Diskurse, Workshops –
                in den Stadtraum getragen. Das Publikum wird so die Möglichkeit
                erhalten, auf die Interventionen im Stadtraum zu reagieren und
                Impulse zu geben.
              </p>
              <p>
                Auf der WWW <b>Agenda</b> stehen in diesem Jahr fünf Szenarien,
                die unseren Alltag bestimmen, die wir verändern und damit unsere
                und die Zukunft von uns allen gestalten können.
              </p>
              <h3>Die Szenarien</h3>
              <p>
                1. Entwicklung / Fortschritt / Neuerungen / Utopien /
                Digitalisierung
                <br />
                2. Lebensformen / Wie wollen wir leben, wohnen, arbeiten? /
                Partnerschaft / Familie
                <br />
                3. Miteinander / Gleichberechtigung / Teilhabe / Diskriminierung
                / Rassismus
                <br />
                4. Klima / Umwelt / Natur / Ernährung / Ressourcen
                <br />
                5. Freiheit / Sicherheit / Verantwortung / Gemeinschaft /
                Individualisierung
                <br />
              </p>
              <p>
                Dabei erkennen wir, dass alles zusammen hängt. Wenn ich in dem
                einen Szenario etwas ändere, beeinflusse ich auch andere. Das
                reicht bis dahin, dass politische, wirtschaftliche Systeme
                insgesamt beeinflusst werden - sogar die Natur, deren Teil wir
                sind.
              </p>
              <h3>Die Fragen</h3>
              <p>
                Wie möchten wir die Zukunft gestalten? Was wollen wir? Wie
                möchten wir behandelt werden? Wie unseren Alltag erleben? Wie
                den Alltag unserer liebsten Menschen? Wie soll unser Lebensraum
                sich um uns herum entwickeln?
              </p>
              <p>
                Diesen Szenarien und Fragen wird im Projekt{' '}
                <b>WorldWideWunderkammer</b> nachgegangen. Aussagen und
                Erkenntnisse werden im Prozess, der Auseinandersetzung und der
                künstlerischen Umsetzung entstehen. Begleitet werden die
                künstlerischen Interventionen von Künstler-Interviews, in denen
                die Künstler ihre persönlichen Positionen erläutern.
              </p>
              <p className="mb-32">
                Die <b>WorldWideWunderkammer</b> wird am 5. Juni mit dem
                Künstlerkollektiv <b>RaumZeitPiraten</b> in den Museen der Stadt
                eröffnet.
              </p>
              <p>
                <b> Wir freuen uns auf eine wunderbare Zeit!</b>
                <br />
                <b>Das Team der Wunderkammer</b>
              </p>
              <div className="pt-32 -mb-16">
                <p>
                  <b>Dokumentation Wunderkammer</b>
                </p>
                <iframe
                  title="Dokumentation Wunderkammer"
                  className="pb-32 h-256 lg:h-512"
                  allow="fullscreen"
                  frameBorder="0"
                  src="https://www.youtube.com/embed/ztH5vp8yETY"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Infos</h1>
      </div>
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
